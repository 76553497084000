import { icons } from "@/icons";

export default function Icon({ icon = null, className = "", title = "" }) {
  if (!icon || !icons?.[icon]) return null;

  if (!className.includes("w-")) {
    className += ` w-6 h-6`;
  }

  return icons?.[icon]?.(className, title);
}
